import React from 'react';
import styles from './Home.module.css';

function Home() {
  return (
    <div className={styles.container}>
      <img src={require('../../assets/York_Floor Design_Logo_PNG.png')} alt="Logo" className={styles.logo} />
      <h1 className={styles.title}>Coming soon</h1>
    </div>
  );
}

export default Home;